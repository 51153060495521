jQuery.noConflict();
(function ($) {
    $(function () {
        $(document).ready(function () {

            //----------------------------------------------------------
            //  Toggle Site Search
            //----------------------------------------------------------

            $('.js-toggleSiteSearch').on('click', function (e) {
                e.preventDefault();
                toggleSiteSearch();
            })

            function toggleSiteSearch() {
                if ($('.c-site-search__form').css("position") != "absolute") {
                    if ($('#top-nav__search-input').val()) {
                        $('.c-site-search__form').submit();
                    }
                } else {
                    var $wrapper = $('.c-site-search'),
                        $toggle = $('.c-site-search__toggle'),
                        $searchBtn = $('.js-toggleSiteSearch > .o-icon');

                    $wrapper.toggleClass('is-active');
                    $toggle.toggleClass('is-active');

                    if ($wrapper.hasClass('is-active')) {
                        $('#top-nav__search-input').focus();
                    }

                    if ($searchBtn) {
                        $searchBtn.toggleClass('o-icon--search');
                        $searchBtn.toggleClass('o-icon--close');
                    }
                }

            }

            //----------------------------------------------------------
            //  Global Magnific Popup
            //----------------------------------------------------------

            if ($('.js-openInLightbox').length > 0) {
                $('.js-openInLightbox').magnificPopup({
                    type: 'image',
                    closeOnContentClick: true,
                    mainClass: 'js-openInLightbox',
                    image: {
                        verticalFit: false
                    },
                    zoom: {
                        enabled: true,
                        duration: 300 // don't foget to change the duration also in CSS
                    }
                });

            } //End Global Magnific Popup

            //------------------------------------------
            //  Fact Check Modal
            //------------------------------------------

            if ($('.js-factCheckModal').length) {
                $('.js-factCheckModal').magnificPopup({ type: 'inline' });
            }

            //------------------------------------------
            //  Phone Context Modal
            //------------------------------------------

            if ($('.js-phoneContextModal').length) {
                $('.js-phoneContextModal').magnificPopup({ type: 'inline' });
            }

            //------------------------------------------
            // Sticky Banner
            //------------------------------------------

            // Sticky banner
            if ($('.c-banner').length > 0) {

                if (getCookie('banner') == 'close') {
                    $('.c-banner').addClass('is-disabled');
                }

                $('.js-bannerClose').on('click', function (e) {
                    e.preventDefault();
                    $('.c-banner').removeClass('is-active').addClass('is-disabled');
                    setCookie('banner', 'close', 1, '/');
                });

                $(function () {
                    var lastScrollTop = 1, delta = 15;

                    $(window).scroll(function (event) {
                        var st = $(this).scrollTop();

                        if (!$('.c-banner').hasClass('is-disabled')) {
                            if (st > 600) {
                                if (Math.abs(lastScrollTop - st) <= delta)
                                    return;
                                if ((st > lastScrollTop) && (lastScrollTop > 0)) {
                                    // Scrolling Down
                                    $('.c-banner').addClass('is-active');
                                } else {
                                    // Scrolling Up
                                    $('.c-banner').removeClass('is-active');
                                }
                                lastScrollTop = st;
                            } else {
                                // Hide within 600px of page top
                                $('.c-banner').removeClass('is-active');
                            }
                        }

                    });
                });
            }

        }); // End Doc Ready
    });

})(jQuery);

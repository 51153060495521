function loadJSArrayOnIntersection(selector, jsURLs, proximityThreshold = '50px') {
  const targetElement = document.querySelector(selector);

  if (!targetElement) {
    return;
  }

  const options = {
    root: null,
    rootMargin: proximityThreshold,
    threshold: 0.0,
  };

  const loadScript = (url) => {
    return new Promise((resolve) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = url;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  };

  const loadScriptsInOrder = async (urls) => {
    for (let url of urls) {
      await loadScript(url);
    }
  };

  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadScriptsInOrder(jsURLs);
        observer.unobserve(entry.target);
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(targetElement);
}
(function() {
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] 
                                   || window[vendors[x]+'CancelRequestAnimationFrame'];
    }
 
    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function() { callback(currTime + timeToCall); }, 
              timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };
 
    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
}());


(function jumpTo(){
    // find 'js-scrollTo' class
    var e = document.getElementsByClassName('js-scrollTo');
    // check if it exists
    if(e.length > 0){

        // validate if jump to has link
        if(!((e[0] == '[href="#"]') || (e[0] == '[href="#0"]'))){
            //assign onclick event for each '.js-scrollTo' class that exists
            for (var i = 0 ; i < e.length; i++) {
                e[i].onclick = scroll;
            }

            function scroll(event) {
                //make sure no foreign characters and link goes to this domain
                if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')  &&  location.hostname == this.hostname) {
                    //prepare this clicked link for target
                    var id = this.getAttribute('href').replace('#', '');
                    // set scroll target by id and target top of the bounding css box
                    var target = document.getElementById(id).getBoundingClientRect().top;
                    // for forms, assign has value to name attr
                    var hash = '[name=' + this.hash.slice(1) + ']';
                    // get offset from attribute, add to target position
                    var offset = this.getAttribute('data-offset');
                    if (offset) target = parseInt(target) + parseInt(offset);
                    // reassign 'target' check to go to h2 id or form
                    target = target.toString ? target : hash;
                    // prevent hash in url
                    event.preventDefault();

                    //Animation Variables
                    var initialPosition = document.documentElement.scrollTop;
                    var targetHeight = document.body.scrollHeight - window.innerHeight > target + initialPosition ? target : document.body.scrollHeight - window.innerHeight;
                    var scrollDuration = 30;
                    var step_x = Math.PI / scrollDuration;
                    var step_count = 0;
                    // Prevent default link actions (assigning # to url)
                    event.preventDefault();
                    //tells the browser to perform an animation using function step()
                    requestAnimationFrame(step);

                    //Animation Controls
                    function step() {
                        if (step_count < scrollDuration) {
                            requestAnimationFrame(step);
                            step_count++;
                            window.scrollTo(0, initialPosition + targetHeight * 0.25 * Math.pow((1 - Math.cos(step_x * step_count)), 2));
                        }
                    }
                }
            }
        }
    }
})(); //Self Invoking Call
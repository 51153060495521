(function( $ ) {
    $( document ).ready(function() {
        $(document).on('click', '.js-showSources', function(e) {
            e.preventDefault();

            var $clicked = $(this),
                target_id = $clicked.data('target'),
                $wrapper = $('#'+target_id),
                $toggle = $wrapper.find('.c-sources__toggle'),
                $list = $wrapper.find('.c-sources__list'),
                list_height = $list.outerHeight();

            $wrapper.animate({
                height: list_height
            }, 200, function() {
                $wrapper.addClass('is-active');
                setTimeout(function() {
                    $toggle.remove()
                    $wrapper.height('auto');
                }, 200);
            });
        })
    });
})(jQuery);
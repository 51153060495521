var feedbackFormSubmit;

(function( $ ) {

    $( document ).ready(function() {

            $('.js-reviewYes').on('click', function(e) {
                e.preventDefault();
                $('[data-step="intro"]').toggleClass('is-active');
                $('[data-step="yes"]').toggleClass('is-active');
                $('[data-step="answer"]').toggleClass('is-active');
            });

            $('.js-reviewNo').on('click', function(e) {
                e.preventDefault();
                $('[data-step="intro"]').toggleClass('is-active');
                $('[data-step="no"]').toggleClass('is-active');
                $('[data-step="answer"]').toggleClass('is-active');
            });


            $('.js-answer').on('click', function(e) {
                e.preventDefault();
                type = $(this).data('type');
                title = $(this).data('title');
                text = $(this).html();
                //Set Hidden Input Fields
                if (type == "yes") {
                    $('input[name="helpful"]').val(1);
                } else {
                    $('input[name="helpful"]').val(0);
                }
                $('input[name="response_type"]').val(text);
                $('input[name="user_id"]').val(getCookie('heirial2.uid'));

                $('.js-feedbackTitle').html(title);

                $('.c-form__step[data-step="'+type+'"]').toggleClass('is-active');
                $('[data-step="answer"]').toggleClass('is-active');
                $('[data-step="form"]').toggleClass('is-active');
            });

    });

    window.feedbackFormSubmit = function() {
        var base_site_url = $('input[name="base_site_url"]').val();
        // group and serialize the post data
        var data = $('.js-feedbackForm').serialize(); // Form data
        // make the post call
        $.ajax( {
            url: base_site_url+'/ajax/article_helpful.php',
            type: 'POST',
            cache: false,
            data: data,
            success: function(data) {
                if(data){
                    $('[data-step="form"]').toggleClass('is-active');
                    $('[data-step="thanks"]').toggleClass('is-active');
                    $('.review-step-indicator__item').addClass('remove-color');
                } else {
                    $('[data-step="form"]').toggleClass('is-active');
                    $('[data-step="thanks"]').toggleClass('is-active');
                    $('.review-step-indicator__item').addClass('remove-color');
                }
            }
        });
        //return false;
    }

})(jQuery);

class Rover {
	constructor(options = {}) {
		this.config = {
			'trackingSelector': '.js-trackClicks',
			'atfSelector': '.c-site-header, .c-hero',
			'debug': false,
			'impressionOptions': {
				'rootMargin': '0px',
				'threshold': 1.0
			}
		};
		this.elements = {};
		this.observing = false;
		for (const key in options) this.config[key] = options[key];
		this.elements.all = Array.from(document.querySelectorAll(this.config['trackingSelector']));
		this.elements.atf = this.getATFElements(this.elements.all, this.config['atfSelector']);
		this.elements.btf = this.getBTFElements(this.elements.all, this.config['atfSelector']);
		this.atfObserver = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					// Do something with the above-the-fold element
					this.sendCustomEvent(this.getTrackingData(entry.target));

					// Stop observing the above-the-fold element
					this.atfObserver.unobserve(entry.target);
					this.checkBTFObserver();
				}
			});
		}, this.config['impressionOptions']);
		this.btfObserver = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					// Do something with the other element
					this.sendCustomEvent(this.getTrackingData(entry.target));
					// Stop observing the other element
					this.btfObserver.unobserve(entry.target);
				}
			});
		}, this.config['impressionOptions']);

		this.elements.atf.forEach(element => {
			this.atfObserver.observe(element);
		});
	}

	getATFElements(tracking, atf) {
		return tracking.filter(element => this.hasParents(element, atf));
	}

	getBTFElements(tracking, atf) {
		return tracking.filter(element => !this.hasParents(element, atf));
	}

	hasParents(element, selector) {
		return element.closest(selector) === null;
	}

	checkBTFObserver() {
		if (this.observing === false) {
			this.observing = true;
			this.startBTFObserver();
		}
	}

	startBTFObserver() {
		this.elements.btf.forEach(element => {
			this.btfObserver.observe(element);
		});
	}

	getTrackingData(target) {
		const element = target;
		const parent = this.getParentOfType(element, ['c-cta', 'c-video', 'c-simple']);
		const action = 'Impression';
		let propName = 'Button';
		let location = this.getLocation(element);
		let props = {};
		if (parent === false) {
			if (element.classList.contains('c-jump-to__link')) {
				const component = element.closest('.c-jump-to');
				propName = 'Navigation';
				props.copy = element.textContent;
				props.order = Array.from(element.closest('.c-jump-to__list').children).indexOf(element.parentNode) + 1;
				props.modifiers = this.getComponentModifiers(component, 'c-jump-to').join(', ');
			}
			if (element.classList.contains('c-button')) {
				props.modifiers = this.getComponentModifiers(element, 'c-button').join(', ');
			}
		} else {
			switch (parent.parentType) {
				case 'c-cta':
					propName = 'CTA';
					props.variant = parent.element.getAttribute('data-variant');
					props.position = this.getPosition(parent.element, '.' + parent.parentType);
					props.relativePosition = this.getRelativePosition(element)
					Object.assign(props, this.getDataTrackingAttributes(element));
					break;
				case 'c-video':
					propName = 'Video'
					props.variant = parent.element.getAttribute('data-variant');
					props.position = this.getPosition(parent.element, '.' + parent.parentType);
					break;
				case 'c-simple':
					propName = 'CTA';
					location.name = 'simple-offer';
					break;
			}
			props.modifiers = this.getComponentModifiers(parent.element, parent.parentType).join(', ');
		}

		props.location = location.name;
		props.offer = this.getOffer(element);
		props.buttonCopy = element.textContent.replace(/[^a-zA-Z 0-9]+/g, '').trim();
		props.action = action;
		props.microIgnore = false;
		props.nonInteraction = true;

		return {
			name: propName,
			props: props
		}
	}

	getDataTrackingAttributes(el) {
		return Object.keys(el.dataset)
			.filter(key => key.startsWith('track'))
			.reduce((obj, key) => {
				obj[key.replace('track', '').toLowerCase()] = el.dataset[key];
				return obj;
			}, {});
	}

	getRelativePosition(el) {
		const type = el.dataset.trackType;
		const elements = document.querySelectorAll('[data-track-type="' + type + '"]');
		const index = Array.prototype.indexOf.call(elements, el);
		return index + 1;
	}

	getParentOfType(el, parentTypes) {
		let parentElement = false;
		parentTypes.forEach(parentType => {
			if (el.classList && el.classList.contains(parentType)) {
				parentElement = { element: el, parentType: parentType };
			}
		});
		if (parentElement) {
			return parentElement;
		}
		const parent = el.parentNode;
		if (parent != undefined) {
			return this.getParentOfType(parent, parentTypes);
		} else {
			return false;
		}
	}

	getComponentModifiers(component, componentClass) {
		const classes = component.className.split(' ');
		const prefix = componentClass + '--';

		const modifiers = classes.filter(modClass => modClass.startsWith(prefix))
			.map(modClass => modClass.replace(prefix, ''));

		return modifiers;
	}

	getPosition(el, selectorClass) {
		const location = el.closest('[data-location]');
		const elements = location.querySelectorAll(selectorClass);
		const index = Array.prototype.indexOf.call(elements, el);

		return index + 1;
	}

	getLocation(el) {
		const element = el.closest('[data-location]');
		return {
			element: element,
			name: element.dataset.location
		}
	}

	getOffer(el) {
		if (el.classList.contains('js-toggleLiveChat')) {
			return 'LiveChat';
		}
		return el.dataset.offer ?? el.getAttribute('href');
	}

	sendCustomEvent(data) {
		if (this.config.debug) {
			console.log(data);
		} else {
			window.customEvent(data.name, data.props);
		}
	}

};
jQuery.noConflict();
(function( $ ) {
  $(function() {
      $( document ).ready(function() {



          $('.js-slideToggle').on('click', function(e) {
              e.preventDefault();

              var $clicked = $(this),
                  $target_selector = $clicked.data('target'),
                  $target = $($target_selector);

              $target.slideToggle();
          });



      });

  });
})(jQuery);

jQuery.noConflict();
(function( $ ) {
  $(function() {
      $( document ).ready(function() {


          $('.js-copyLink').on('click', function(e) {
              e.preventDefault();
              copyLink();
          })

          function copyLink() {

              var input = document.getElementById('copy-link');
              var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

              if (isiOSDevice) {

                var editable = input.contentEditable;
                var readOnly = input.readOnly;

                input.contentEditable = true;
                input.readOnly = false;

                var range = document.createRange();
                range.selectNodeContents(input);

                var selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);

                input.setSelectionRange(0, 999999);
                input.contentEditable = editable;
                input.readOnly = readOnly;

              } else {
                input.select();
              }

              document.execCommand('copy');
          }



      });

  });
})(jQuery);

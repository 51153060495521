jQuery.noConflict();
(function( $ ) {
  $(function() {
      $( document ).ready(function() {



          $('.js-activate').on('click', function(e) {
              e.preventDefault();

              var target = $(this).data('target'),
                  $target = $(target);

              $target.addClass('is-active');
          });



          $('.js-deactivate').on('click', function(e) {
              e.preventDefault();

              var target = $(this).data('target'),
                  $target = $(target);

              $target.removeClass('is-active');
          });



          $('.js-toggleActivate').on('click', function(e) {
              e.preventDefault();

              var target = $(this).data('target'),
                  $target = $(target);

              $target.toggleClass('is-active');
          });



      });

  });
})(jQuery);

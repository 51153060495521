// IE Browser detection for deprecation alert
if (!!window.navigator.userAgent.match(/MSIE|Trident/)) {
    var updateBanner = document.querySelector('.c-alert--browser-update');
    var body = document.querySelector('body');

    // moves banner from footer to top of page when IE browser is detected
    if (updateBanner) {
        body.insertBefore(updateBanner, body.childNodes[0]);
        updateBanner.style.display = 'block';
    }
}

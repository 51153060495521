jQuery( document ).ready(function( $ ) {
    //------------------------------------------
    // 	 Polyfills
    //------------------------------------------
    if (!Modernizr.cssremunit){
        jQuery.getScript("/wp-content/themes/Ares/global-assets/js/plugins/polyfills/rem.js");
    }

    if (!Modernizr.mediaqueries){
        jQuery.getScript("/wp-content/themes/Ares/global-assets/js/plugins/polyfills/respond.js");
    }

    if (!Modernizr.objectfit){
        jQuery.getScript("/wp-content/themes/Ares/global-assets/js/plugins/polyfills/ofi.js", function() {
            objectFitImages();
        });
    }
});
